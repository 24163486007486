<template>
  <v-app>
    <div style="height: 100vh; background-color:rgba(115, 64, 188, 0.5);"  :style="bgLayer" class="bgLayer1">
        <v-row no-gutters>
              <v-col cols="4"></v-col>
              <v-col cols="4">
                  <div>
                      <!-- <v-row no-gutters class="my-10">
                        <v-col cols="6" >
                            <v-img
                              src="../../assets/images/geomineraba-logo.png"
                              height="75"
                              contain
                              class="mx-3"
                            ></v-img>
                        </v-col>
                        <v-col cols="6">
                            <v-img
                              src="../../assets/images/skymap-logo.png"
                              height="75"
                              contain
                              class="mx-3"
                            ></v-img>
                        </v-col>
                      </v-row> -->
                        <p class="text-center subscription">SKYMAP GLOBAL DATA SERVICE</p>
                        <p class="text-center d-flex justify-space-around svcContainer mb-15">
                          <span class="services">MONITOR</span> <span class="services">DETECT</span> <span class="services">IDENTITY</span>
                        </p>

                        <div style="background: #FFFFFF; border-radius: 20px;" class="accountContainer">
                        <v-form @submit="login" onSubmit="return false" class="d-flex flex-column flex-none" v-model="valid" ref="formLogin">
                          <div style="flex: none; padding-left: 33px; padding-right: 33px; padding-bottom: 40px; height: 100%;">
                            <!-- <span style="font-size: 22px; font-weight: bold;">Email</span> -->
                            <div style="width: 100%;" class="mt-14 d-inline-flex input-login accountBox">
                              <v-icon class="accountIcons" style="height:50px;">mdi-account</v-icon>
                              <v-text-field
                                  height="50"
                                  background-color="white"
                                  dense
                                  flat
                                  solo
                                  v-model="email"
                                  label="USERNAME"
                                  placeholder="USERNAME"
                                  style="color: black !important;"
                                  :rules="[rules.requiredUserName]"
                              >
                              </v-text-field>
                            </div>
                            <!-- <span style="font-size: 22px; font-weight: bold;;">Password</span> -->
                            <div style="width: 100%;" class="d-inline-flex input-login accountBox mt-12">
                               <v-icon class="accountIcons" style="height:50px;">mdi-lock-outline</v-icon>
                              <v-text-field
                                  height="50"
                                  flat
                                  background-color="white"
                                  solo
                                  :type="isShowPassword ? 'text' : 'password'"
                                  v-model="password"
                                  label="PASSWORD"
                                  placeholder="PASSWORD"
                                  :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="isShowPassword = !isShowPassword"
                                  :rules="[rules.requiredPassword]"
                              >
                              </v-text-field>
                            </div>
                            <div style="width: 100%; height: 20px;" class="FgMg-Top">
                              <div class="fill-height d-flex justify-end mt-15">
                                <ForgotPassword/>
                              </div>
                            </div>
                            <div style="width: 100%; text-align: center;" class="pt-5">
                              <v-btn color="#893FF2;" width="100%" height="50" type="submit" style="background: #F9F7FC; border-radius: 4px; box-shadow: 0px 1px 4px rgba(107, 21, 228, 0.34); text-transform: capitalize; color:#893FF2; border:solid 2px;">Login</v-btn>
                            </div>
                          </div>
                        </v-form>
                        </div>
                        <p class="mt-9 text-center copyright">Presented by Skymap Global and Powered by EOFactory.ai&copy;</p>
                        
                    </div>
              </v-col>
              <v-col cols="4"></v-col>
            </v-row>

     
    </div>
  </v-app>
</template>

<script>
import {
  authLogin,
  checkToken
} from "@/backend";
import config from '@/config.json'
import AvatarPopOver from "@/components/AvatarPopOver";
import {mapState} from "@/store/ults";
import api from "@/api";
import ForgotPassword from "@/views/forgotPassword/ForgotDialog";
export default {
  components: {
    ForgotPassword,
    AvatarPopOver
  },
  data: () => ({
    loading: false,
    valid: false,
    popupForgot: false,
    rules: {
      requiredUserName: value => (!!value && !!value.trim()) || 'Email is required',
      requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
    },
    email: undefined,
    password: undefined,
    isShowPassword: false,
    bgLayer: {
      backgroundImages: `url(${require('@/assets/images/bg.svg')})`
    }
  }),
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  created () {
    const token = api.getToken()
    if (token) this.checkTokenExist()
  },
  methods: {
    async login () {
      this.$refs.formLogin.validate()
      if (!this.valid) return
      try {
        this.loading = true
        const res = await authLogin({email: this.email, password: this.password})
        location.href = `${location.origin}/app`
      } catch (e) {
        console.log(e.message)
        alert('Data invalid')
      } finally {
        this.loading = false
      }
    },
    async checkTokenExist () {
      try {
        const res = await checkToken()
        location.href = `${location.origin}/app`
      } catch (e) {
        console.log(e.message)
        api.deleteToken()
      }
    },
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({ path: link });
    }
  }
};
</script>
<style scoped>
/deep/
/* .input-login .v-input__slot {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
  color: black !important;
} */
/deep/
.input-login v-input {
  color: black !important;
}

.accountContainer{
  height: 600px;
  width: 80%;
  margin-top: 10vh;
  margin-left: auto;
  margin-right:auto;
}

.bgLayer1{
  background-image: url('~@/assets/images/bg.svg');
  background-repeat: no-repeat !important;
  background-size: cover;
}

.subscription{
  font-weight: bold;
  font-size: 38px;
  line-height: 40px;
  /* or 171% */
  text-align: center;
  letter-spacing: 0.08em;
  font-variant: small-caps;
  color: #FFFFFF;
}

.services {
  font-size: 26px;
  line-height: 40px;
  letter-spacing: 0.04em;
  font-variant: small-caps;
  color: #FFFFFF;
}

.svcContainer{
  width: 450px;
  margin: auto;
}

.copyright{
  position: absolute;
  bottom: 0;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  /* font-variant: small-caps; */
  color: #000000;
  margin-left: auto; 
  margin-right: auto; 
  left: 0;
  right: 0;
}

.accountBox{
  border: 3px solid #D6C4EF;
  box-sizing: border-box;
  border-radius: 4px;
}

.accountIcons{
  font-size: 80px;
  transform: translate(0px, 13px);
}

i.v-icon.notranslate.accountIcons.mdi.mdi-lock-outline.theme--light{
    transform: translate(0px, 16px);
}

.FgMg-Top{
  margin-top: 200px;
}
</style>
<style>
html,
body,
.app {
}
</style>
